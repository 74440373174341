export default class MenuComponent {
    constructor() {
        this.showSubmenu()
    }

    static #mainEntries = $('.menu__main-entries > .menu__entry[data-customer-menu]')
    static #subEntries = $('.menu__submenus > .menu__submenus__items[data-customer-submenu]')
    static #submenus = $('.js-submenus')

    static #getSubEntries(titleSlugged) {
        const $selector = $(
            `.menu__submenus .menu__submenus__items[data-customer-submenu="${titleSlugged}"]`,
        )
        return $selector.length ? $selector : false
    }

    showSubmenu() {
        const $menuEntries = $('.js-menu-entry')
        const $subEntryReturn = $('.js-sub-entry-return')

        $('.js-menu-entry .menu__entry__link').on('click', function (e) {
            const titleSlugged = $(this).parent('.js-menu-entry').data('customer-menu')

            // second click (already open)
            if ($(this).parent('.js-menu-entry').hasClass('is-active')) {
                if (window.matchMedia('(max-width: 1200px)').matches) {
                    e.preventDefault()
                } else {
                    return true
                }
            }

            // first click (open submenu)
            $menuEntries.removeClass('is-active')
            MenuComponent.#subEntries.removeClass('is-active')
            $(this).parent('.js-menu-entry').addClass('is-active')
            MenuComponent.#getSubEntries(titleSlugged).addClass('is-active')

            if (window.matchMedia('(max-width: 1200px)').matches) {
                MenuComponent.#submenus.addClass('submenu-open')
            }

            return false
        })

        if (window.matchMedia('(max-width: 1200px)').matches) {
            if ($menuEntries.hasClass('is-active')) {
                $menuEntries.removeClass('is-active')
            }

            if (MenuComponent.#subEntries.hasClass('is-active')) {
                MenuComponent.#subEntries.removeClass('is-active')
            }

            $subEntryReturn.on('click', () => {
                $menuEntries.removeClass('is-active')
                MenuComponent.#submenus.removeClass('submenu-open')
            })
        }
    }

    static resetMenu() {
        const titleSlugged =
            'undefined' !== typeof MenuComponent.#mainEntries.first().data('customer-menu')
                ? MenuComponent.#mainEntries.first().data('customer-menu')
                : false

        MenuComponent.#mainEntries.removeClass('is-active')
        MenuComponent.#subEntries.removeClass('is-active')
        MenuComponent.#submenus.removeClass('is-active')

        if (window.matchMedia('(min-width: 1200.1px)').matches) {
            $('.first-with-submenu').addClass('is-active')
            if (false !== titleSlugged) {
                MenuComponent.#getSubEntries(titleSlugged).addClass('is-active')
            }
        }

        if (window.matchMedia('(max-width: 1200px)').matches) {
            MenuComponent.#submenus.removeClass('submenu-open')
        }
    }
}
