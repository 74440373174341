export default class BackToTopComponent {
    constructor() {
        this.init()
    }

    init() {
        const backToTop = $('.js-back-to-top')

        if (backToTop.length > 0) {
            backToTop.on('click', () => {
                $('html, body').animate(
                    {
                        scrollTop: 0,
                    },
                    1000,
                )
            })
        }
    }
}
