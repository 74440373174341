import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

import UtilsService from '../services/utils.service'

export default class BannerComponent {
    constructor() {
        BannerComponent.init()
    }

    static async init() {
        const videoContainer = '[data-banner-video]'
        const swiperContainer = '[data-banner-slider]'

        // Show banner video only on desktop
        if ($(videoContainer).length > 0 && window.matchMedia('(min-width: 1001px)').matches) {
            const videoElmt = document.createElement('video')
            const videoSrc = document.createElement('source')

            // config
            videoSrc.type = $(videoContainer).data('banner-video-type')
            videoSrc.src = $(videoContainer).data('banner-video-src')
            videoElmt.muted = true
            videoElmt.loop = true

            videoElmt.appendChild(videoSrc)
            $(videoContainer).append(videoElmt)
            videoElmt.play()
        } else if ($(swiperContainer).length > 0) {
            let options = {
                loop: true,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                speed: 1000,
                autoplay: {
                    delay: 5000,
                },
                navigation: {
                    nextEl: '.js-header-banner-slider-navigation-arrow-next',
                    prevEl: '.js-header-banner-slider-navigation-arrow-prev ',
                },
                grabCursor: true,
            }

            UtilsService.swiperWrapper(swiperContainer)

            if (window.matchMedia('(max-width: 1000px)').matches && $('body.home').length > 0) {
                options = {
                    ...options,
                    effect: 'slide',
                    spaceBetween: 10,
                    loop: false,
                    autoplay: false,
                    navigation: false,
                    pagination: {
                        el: '.js-header-banner-slider-pagination',
                        type: 'bullets',
                    },
                }
            }

            window.bannerSlider = {
                ...window.bannerSlider,
                homeSlider: {
                    swiper: await Swiper.create(swiperContainer, options),
                },
            }
        }
    }
}
